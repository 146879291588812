import { Table } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";
import { Suspense } from "react";
import { Await } from "react-router-dom";

const AsyncTable = ({
  children,
  columns,
  currentPage,
  noDataMessage,
  onChangePage,
  paginationAriaLabel,
  resolve,
}) => (
  <Suspense fallback={<Table columns={columns} />}>
    <Await
      resolve={resolve}
      errorElement={
        <Table
          columns={columns}
          errorMessage="Something went wrong. Please try again later."
          hasError
        />
      }>
      {({ data, pagination }) => (
        <Table
          columns={columns}
          data={data}
          noDataMessage={noDataMessage}
          pagination={{
            "aria-label": paginationAriaLabel,
            count: pagination.pages,
            onChange: onChangePage,
            page: currentPage,
          }}>
          {data => data.map(row => children(row))}
        </Table>
      )}
    </Await>
  </Suspense>
);

AsyncTable.propTypes = {
  children: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPage: PropTypes.number,
  noDataMessage: PropTypes.string.isRequired,
  onChangePage: PropTypes.func.isRequired,
  paginationAriaLabel: PropTypes.string.isRequired,
  resolve: PropTypes.shape({
    catch: PropTypes.func.isRequired,
    then: PropTypes.func.isRequired,
  }).isRequired,
};

export default AsyncTable;
