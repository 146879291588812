import { Dialog } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";
import { useState } from "react";

import Content from "components/SessionDetailsDialog/Content.jsx";

const SessionDetailsDialog = ({
  disableJoinButton,
  handleJoinClick,
  showJoinSessionButton,
  slots = { trigger: Dialog.Trigger },
  title,
  uuid,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}>
      <Dialog.Trigger
        className="pl-1 text-xs text-brand-500 text-left hover:text-brand-700 hover:underline"
        slots={{ root: slots.trigger }}
        data-testid="view-session-details-btn">
        View Session Details
      </Dialog.Trigger>
      <Dialog.Body
        className="md:max-w-2xl"
        data-testid="session-details-dialog">
        <Dialog.Heading>
          <Dialog.Title className="text-xl">{title}</Dialog.Title>
        </Dialog.Heading>
        <Dialog.Content className="divide-y divide-zinc-200 space-y-4 text-zinc-700 min-h-[420px] flex flex-col">
          <Content
            disableJoinButton={disableJoinButton}
            handleJoinClick={handleJoinClick}
            showJoinSessionButton={showJoinSessionButton}
            uuid={uuid}
          />
        </Dialog.Content>
      </Dialog.Body>
    </Dialog>
  );
};

SessionDetailsDialog.propTypes = {
  disableJoinButton: PropTypes.bool,
  handleJoinClick: PropTypes.func,
  showJoinSessionButton: PropTypes.bool,
  slots: PropTypes.shape({ trigger: PropTypes.elementType }),
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default SessionDetailsDialog;
