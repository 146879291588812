import { DateRangePicker } from "@Ignite-Reading/ui-kit/components";
import { useState } from "react";

const FilterByDate = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <div className="flex items-center space-x-3">
      <span className="text-gray-500 text-sm">Filter by date:</span>
      <div className="flex items-center space-x-3">
        <DateRangePicker
          endDate={endDate}
          onChangeEndDate={setEndDate}
          onChangeStartDate={setStartDate}
          startDate={startDate}
          showClearButton
        />
      </div>
    </div>
  );
};

export default FilterByDate;
