import PropTypes from "prop-types";

import PresencePill from "components/PresencePill";
import SessionDetailsDialog from "components/SessionDetailsDialog";
import { generateStudentEditLink } from "utils";

const UpcomingSessionItem = ({
  date,
  startTime,
  student,
  studentUuid,
  subTutorPending,
  uuid,
}) => (
  <div className="flex items-center justify-between py-3.5 px-5 space-x-4 group-last:rounded-b-lg">
    <div className="flex flex-col space-y-0.5">
      <ul className="flex flex-wrap">
        <li className="p-1">
          <PresencePill
            data-testid="tutor-pill"
            hideStatusDot
            isSub={subTutorPending}
          />
        </li>
        <li className="p-1">
          <PresencePill
            data-testid="student-pill"
            hideStatusDot
            href={generateStudentEditLink(studentUuid)}>
            {student}
          </PresencePill>
        </li>
      </ul>
      <SessionDetailsDialog
        title="Upcoming session"
        uuid={uuid}
      />
    </div>
    <div className="flex flex-col items-end space-y-0.5">
      <p className="text-xs text-zinc-700 font-semibold whitespace-nowrap">
        {date}
      </p>
      <p className="text-xs text-zinc-700">{startTime}</p>
    </div>
  </div>
);

UpcomingSessionItem.propTypes = {
  date: PropTypes.string,
  district: PropTypes.string,
  school: PropTypes.string,
  startTime: PropTypes.string,
  student: PropTypes.string,
  studentUuid: PropTypes.string,
  subTutorPending: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
};

export default UpcomingSessionItem;
