import { Skeleton } from "@Ignite-Reading/ui-kit/components";
import * as PropTypes from "prop-types";

import useGetLessonSpaceSessionPlaybacks from "./useGetLessonSpaceSessionPlaybacks";

const LessonSpaceSessionPlaybacks = ({ sessionId }) => {
  const { data, hasError, hasLoaded } =
    useGetLessonSpaceSessionPlaybacks(sessionId);

  return (
    <div
      className="pt-6 pb-4 space-y-2"
      data-testid="session-detail-recordings">
      <div className="flex items-center justify-between">
        <h2 className="font-semibold text-xl">Recordings</h2>
      </div>
      <div className="text-sm text-zinc-500 py-2">
        {!hasLoaded ? (
          hasError ? (
            <p className="flex items-center justify-center grow text-red-400">
              There was an error loading recordings.
            </p>
          ) : (
            <Skeleton
              height="20"
              width="110">
              <Skeleton.Rect
                y="4"
                rx="3"
                ry="3"
                width="110"
                height="13"
              />
            </Skeleton>
          )
        ) : data.length > 0 ? (
          <ul className="grid grid-cols-4 gap-x-2 gap-y-2">
            {data.map(({ playback_url, uuid }, index) => (
              <li key={uuid}>
                <a
                  className="text-brand-500 hover:text-brand-700 hover:underline"
                  href={playback_url}
                  target="_blank"
                  rel="noopener noreferrer">
                  View Recording {index + 1}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <em data-testid="no-recordings">
            Recordings are available after sessions are completed.
          </em>
        )}
      </div>
    </div>
  );
};

LessonSpaceSessionPlaybacks.propTypes = {
  sessionId: PropTypes.number.isRequired,
};

export default LessonSpaceSessionPlaybacks;
