import { Table } from "@Ignite-Reading/ui-kit/components";
import { useCallback } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";

import AsyncTable from "components/AsyncTable";
import FilterByDate from "components/FilterByDate";
import { BASE_10, PAGE_SEARCH_PARAM } from "constants";
import { generateStudentEditLink } from "utils";

const AllSessionsView = () => {
  const { allSessionsPromise } = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get(PAGE_SEARCH_PARAM), BASE_10);
  const onChangePage = useCallback(
    page => {
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        page,
      }));
    },
    [setSearchParams]
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold text-zinc-700 flex items-center">
          All Sessions
        </h3>
        <FilterByDate />
      </div>
      <AsyncTable
        currentPage={isNaN(currentPage) ? 1 : currentPage}
        columns={[
          { id: "student", label: "Student" },
          { id: "primary_tutor", label: "Tutor" },
          { id: "sub_tutor", label: "Sub Tutor" },
          { id: "date", label: "Date" },
          { id: "start_time", label: "Time" },
          { id: "status", label: "Status" },
        ]}
        onChangePage={onChangePage}
        resolve={allSessionsPromise}
        noDataMessage="There are no sessions to display."
        paginationAriaLabel="All sessions pagination">
        {row => (
          <Table.Row key={row.id}>
            <Table.Cell>
              <a
                className="font-semibold text-brand-500 hover:text-brand-700 hover:underline"
                href={generateStudentEditLink(row.student_uuid)}
                rel="noopener noreferrer"
                target="_blank"
                data-testid="student-name">
                {row.student}
              </a>
            </Table.Cell>
            <Table.Cell>{row.primary_tutor ?? "None"}</Table.Cell>
            <Table.Cell>
              {row.sub_tutor_pending
                ? "Pending"
                : row.sub_tutor
                  ? row.sub_tutor
                  : "None"}
            </Table.Cell>
            <Table.Cell>{row.date}</Table.Cell>
            <Table.Cell>{row.start_time}</Table.Cell>
            <Table.Cell>{row.status}</Table.Cell>
          </Table.Row>
        )}
      </AsyncTable>
    </div>
  );
};

export default AllSessionsView;
